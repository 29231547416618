<script setup lang="ts">
import {CFormInput, CButton} from "@coreui/vue";

const props = defineProps({
  containerResultsClass: {
    type: String,
    default: '',
  },
  containerMultipleResultsClass: {
    type: String,
    default: '',
  },
});

const store = useTrackingStore();
const {isLookingNumber, isLoadingGuestTrackingSession, maxTrackingSessionsNumber, trackingSessions} = storeToRefs(store);

const registrationNumber = ref<string>('');
const isSearchDisabled = computed(() => registrationNumber.value.length <= 2 || isLookingNumber.value);

const { t } = useI18n();

const containerClass = computed<string>(() => {
  return (trackingSessions.value.length > 0 ? props.containerResultsClass : '')
    + ' '
    + (trackingSessions.value.length > 1 ? props.containerMultipleResultsClass : '');
});

const find = () => {
  const telegramWebApp = useTelegramWebApp();
  store.startTracking(registrationNumber.value)
      .then(session => {
        if (session) {
          registrationNumber.value = '';
        } else {
          telegramWebApp.showAlert(t('telegram.tracking.no_car_found'));
        }
      })
      .catch((error) => {
        telegramWebApp.showAlert(t('error_occurred_try_later'));
        captureException(error);
      });
};
</script>

<template>
  <div :class="containerClass">
    <div
      v-if="isLoadingGuestTrackingSession"
      class="d-flex w-75 m-auto"
    >
      <IconLoading
        :size="24"
        class="success m-auto"
      />
    </div>
    <div
      v-else-if="trackingSessions.length < maxTrackingSessionsNumber"
      class="d-flex w-75 m-auto"
    >
      <CFormInput
        v-model="registrationNumber"
        type="search"
        class="me-2"
        maxlength="10"
        :placeholder="t('telegram.tracking.find_placeholder')"
        :disabled="isLookingNumber"
        @keyup.enter="find"
      />
      <CButton
        type="button"
        color="dark"
        variant="outline"
        class="d-inline-flex"
        :disabled="isSearchDisabled"
        @click="find"
      >
        <IconLoading
          v-if="isLookingNumber"
          :size="24"
          class="dark"
        />
        {{ t('telegram.tracking.find') }}
      </CButton>
    </div>
    <template v-if="trackingSessions.length > 0">
      <TelegramMiniTrackingSession
        v-for="session in trackingSessions"
        :key="session.id"
        :session="session"
      />
    </template>
  </div>
</template>
