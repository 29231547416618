<script setup lang="ts">
import '~/assets/scss/tlgrm.scss'
import {CContainer} from "@coreui/vue";

const {isSummaryLoaded} = storeToRefs(useSummaryStore());
</script>

<template>
  <div>
    <CContainer class="px-2 py-2">
      <TelegramMiniTracking
        v-if="isSummaryLoaded"
        container-results-class="position-sticky top-2 z-1"
        container-multiple-results-class="border rounded p-2 bg-body"
        class="mb-2 mt-1"
      />
      <slot />
    </CContainer>
    <AppToaster />
  </div>
</template>